@import "theme/Theme";

* {
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
#root,
.main {
  @include desktop {
    min-height: 100vh;
  }
}

#root {
  position: relative;
  @include tablet {
    min-height: 100vh;
    padding-top: 66px;
  }
}

body {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  //overflow-x: hidden;
  line-height: 1.6;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 14px;
  @include desktop {
    font-size: 16px;
    color: #fff;
    background-color: #222;
  }
}

*:last-child {
  margin-bottom: 0;
}

.container {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 0 20px;
  &--full {
    max-width: 100%;
    //display: block;
  }
  &.align-middle {
    align-items: center;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0 0 1rem;
}

p {
  margin: 0 0 1rem;
}

a {
  color: $primary-color;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:before {
    transition: all 0.2s ease-in-out;
  }
}

.button {
  border-radius: 50px;
  font-weight: 700;
  border: none;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 12px 26px 13px;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  @include desktop {
    padding: 15px 46px;
  }
  &.secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  &:hover {
    background-color: #fff;
    color: #000;
    outline: 0;
  }
  &-group {
    .button {
      @include tablet {
        margin-left: 30px;
        &:first-child {
          margin-left: 0;
        }
      }
      @include desktop {
        margin: 0 25px;
      }
    }
  }
}

.main {
  position: relative;
  padding-top: 20px;
  padding-bottom: 100px;
  @include desktop {
    padding-top: $section-padding;
    padding-bottom: $section-padding;
  }
}
